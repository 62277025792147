<template>
    <div class="container mt-sm-5 mt-3">
        <div class="row justify-content-center">
            <div class="col-xl-10">
                <div class="ms-panel">
                    <div class="row">
                        <div class="col-sm-4 mb-3">
                            <index-action to_name="roleList" title="Role"></index-action>
                        </div>
                    </div>
                    <div class="ms-panel-body mb-5 p-4 border">
                        <form @submit.prevent="editMode ? update() : save()">
                            <div class="mb-3 row">
                                <label for="first-name" class="col-sm-2 col-form-label has-error">Role Name <span
                                    class="text-danger">*</span></label>
                                <div class="col-sm-10">
                                    <input placeholder="Role Name" required :class="{ 'is-invalid': errors['name'] }" v-model="form.name"
                                           type="text" class="form-control" id="first-name">
                                    <div v-if="errors['name']" class="invalid-feedback">
                                        {{ errors['name'][0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 permissions" v-for="(role, index) in roles_permissions">
                                    <div class="row mb-3">
                                        <div class="col-md-4">
                                            <h5 style="text-transform: capitalize">{{ index }}</h5>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="checkbox">
                                                <label>
                                                    <input @click="selectAll(role, $event)" type="checkbox"
                                                           class="check_all input-icheck"> All
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="col-md-12" v-for="(permission, key) in role">
                                                <div class="checkbox text-capitalize">
                                                    <label>
                                                        <input class="permission" :value="permission.id"
                                                               v-model="permissions"
                                                               type="checkbox">
                                                        {{ permission.name }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <button class="btn btn-secondary me-2" type="reset">Reset</button>
                            <button v-if="editMode" class="btn btn-primary d-inline" type="submit">Update</button>
                            <button v-else class="btn btn-primary d-inline" type="submit">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";

export default {
    name: "Create",
    data() {
        return {
            roles_permissions: [],
            permissions      : [],
            form             : {
                name       : '',
                guard_name : 'api',
                permissions: [],
            },
            editMode         : false,
            selected         : [],
            errors           : [],
        }
    },
    mounted() {
        this.getPermissions();
        if (this.$route.params.id) {
            this.editMode = true;
            this.edit(this.$route.params.id);
        }
    },
    computed: {},
    methods : {
        selectAll(permission, $event) {
            permission.forEach((per) => {
                if ($event.target.checked == true) {
                    if (this.permissions.indexOf(per.id) === -1) {
                        this.permissions.push(per.id);
                    }
                } else {
                    var index = this.permissions.indexOf(per.id);
                    this.permissions.splice(index, 1);
                }
            });
        },
        getPermissions() {
            ApiService.get(`/dropdown/permissions`).then((res) => {
                this.roles_permissions = res.data;
            }).catch(errors => {
                NotificationService.error(errors.response.data.message);
            })
        },
        save() {
            this.form.permissions = this.permissions;
            ApiService.post('/roles', this.form).then((res) => {
                this.errors = []
                NotificationService.success(res.data.message);
                this.$router.push('/role/list');
                this.formReset();
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            ApiService.get(`/roles/${id}`).then((res) => {
                this.form = res.data.data;
                res.data.data.permissions.forEach((per) => {
                    this.permissions.push(per.id);
                });
            }).catch(errors => {
                NotificationService.error(errors.response.data.message);
            })
        },
        update() {
            this.form.permissions = this.permissions;
            ApiService.update(`/roles/${this.form.id}`, this.form).then((res) => {
                this.errors = []
                // this.$router.push('/role/list');
                // this.formReset();
                NotificationService.success(res.data.message);
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.permissions = [];
            this.form        = {
                name       : '',
                guard_name : 'api',
                permissions: [],
            }
        }
    },
    watch   : {
        $route(to, from) {
            this.formReset();
        }
    }
}
</script>
